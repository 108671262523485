
.navbar {
    background: #111d2c;
    padding-left: 1rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .navLogo {
    height: 32px;
    width: 200px;
    background-color: rgba(255, 255, 255, 0.2);;
    margin: 1rem;
  }
  

@media (min-width: 992px) {
  .menu {
    display: none;
  }
  .navbar {
    display: none;
  }
}

