/* @import '~antd/dist/antd.css'; */
/* @import '~antd/dist/antd.dark.css'; */
@import '~react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
@import '~antd/dist/antd.compact.css';
@import '~react-tiny-fab/dist/styles.css';

.logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
  text-align: center;
  vertical-align: middle;
  line-height: 32px;
  width: auto;
}

.card-without-padding {
  padding: 0;
  margin: 0;
}

.site-layout .site-layout-background {
  background: #fff;
}

.card-container p {
  margin: 0;
}
.card-container > .ant-tabs-card .ant-tabs-content {
  margin-top: -16px;
}
.card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  background: #fff;
  padding: 16px;
}
.card-container > .ant-tabs-card > .ant-tabs-nav::before {
  display: none;
}
.card-container > .ant-tabs-card .ant-tabs-tab,
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab {
  border-color: transparent;
  background: transparent;
}
.card-container > .ant-tabs-card .ant-tabs-tab-active,
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
  border-color: #fff;
  background: #fff;
}
#components-tabs-demo-card-top .code-box-demo {
  background: #f5f5f5;
  overflow: hidden;
  padding: 24px;
}
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-content {
  height: 120px;
  margin-top: -8px;
}
[data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-tab {
  border-color: transparent;
  background: transparent;
}
[data-theme='dark'] #components-tabs-demo-card-top .code-box-demo {
  background: #000;
}
[data-theme='dark']
  .card-container
  > .ant-tabs-card
  .ant-tabs-content
  > .ant-tabs-tabpane {
  background: #141414;
}
[data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
  border-color: #141414;
  background: #141414;
}

.responsive-table-bordered > .ant-table-bordered {
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: #f0f0f0;
}

.bordered {
  border-width: 1px;
  border-style: solid;
  border-color: #f0f0f0;
}

.border {
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: #f0f0f0;
  text-align: center;
  vertical-align: middle;
  line-height: 15px;
  width: auto;
}

.collapse-as-card {
  background: #fff;
  padding-top: 10;
  padding-bottom: 10;
}

.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-horizontal > .ant-menu-submenu-selected {
  color: red;
  border-bottom: 2px solid red;
}

@media screen and (max-width: 40em) {
  /*
    Force table elements to not behave like tables anymore
    Hide table headers (but not display: none;, for accessibility)
  */

  .bordered {
    border-width: 0px;
  }

  .responsiveTable table,
  .responsiveTable thead,
  .responsiveTable tbody,
  .responsiveTable th,
  .responsiveTable td,
  .responsiveTable tr {
    display: block;
  }

  .responsiveTable thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
    /* border-bottom: 0px solid #333; */
  }

  .responsiveTable tbody tr {
    border: 1px solid #000000;
    padding: 0.1em;
    margin-bottom: 10px;
    background: #fff;
  }

  .responsiveTable td.pivoted {
    /* Behave like a "row" */
    border: none !important;
    position: relative;
    padding-left: calc(40% + 10px) !important;
    text-align: left !important;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    line-height: 5mm;
  }

  .responsiveTable td .tdBefore {
    /* Now like a table header */
    position: absolute;
    display: block;

    /* Top/left values mimic padding */
    left: 1rem;
    width: calc(40% - 10px);
    white-space: pre-wrap;
    overflow-wrap: break-word;
    text-align: left !important;
    font-weight: 600;
    line-height: 5mm;
  }
}

@media screen and (max-width: 992px) {
  .hide-on-mobile {
    display: none;
  }
}

@media screen and (min-width: 992px) {
  .hide-on-desktop {
    display: none;
  }
}

.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-open,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover,
.ant-menu-item .ant-menu-item-only-child {
  color: unset;
}
